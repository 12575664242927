import { runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Form, Input, InputGroup } from "reactstrap";
import { makeUrl } from "../../../infrastructure/httpClient";
import service, { IExternalProviderDto } from "../../../services/account/AccountService";
import * as C from "./StyledComponents";

export interface IExternalLoginsProps {
	logins: IExternalProviderDto[];
	returnUrl: string | null;
	isSubmitting: boolean;
	beginSubmit: () => void;
	endSubmit: () => void;
	setSuccess: (message: string) => void;
	setError: (message: string) => void;
}

@observer
export class ExternalLogins extends React.Component<IExternalLoginsProps> {
	private readonly unlink = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		this.props.beginSubmit();
		try {
			const provider: string = event.currentTarget.value;
			await service.unlinkExternalLogin(provider);

			runInAction(() => this.props.setSuccess("Successfully unlinked external account."));
		} catch (e) {
			runInAction(() => this.props.setError((e as Error).message));
		} finally {
			this.props.endSubmit();
		}
	}

	public render(): React.ReactNode {
		return (
			<React.Fragment>
				<C.Headline text="External logins" />
				{this.props.logins.map(l =>
					<InputGroup key={l.name}>
						<C.LeftBlock>
							{l.displayName}
						</C.LeftBlock>
						<C.RightBlock>
							{l.linked
								? "Linked"
								: "Not linked"}
						</C.RightBlock>
						{l.linked &&
							<C.EditButton
								color="default"
								disabled={this.props.isSubmitting}
								onClick={this.unlink}>
								Unlink
							</C.EditButton>}
						{!l.linked &&
							<Form
							action={makeUrl("account/external-login/link")}
							method="POST">
								{this.props.returnUrl !== null &&
									<Input
										type="hidden"
										name="returnUrl"
										value={this.props.returnUrl} />}
								<Input type="hidden" name="provider" value={l.name} />
								<C.EditButton
									type="submit"
									color="default"
									disabled={this.props.isSubmitting}>
									Link
								</C.EditButton>
							</Form>}
					</InputGroup>)}
			</React.Fragment>
		);
	}
}