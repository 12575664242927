import { action, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React, { SyntheticEvent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Alert, Button, Col, FormFeedback, Input, InputGroupAddon, Row } from "reactstrap";
import { Container, InputGroupWithValidation } from "../../components";
import { goTo } from "../../helpers/goTo";
import { getQueryStringParameter } from "../../helpers/queryString";
import { EmailRegex } from "../../helpers/regex";
import service from "./ResendConfirmationEmailService";

@observer
class ResendConfirmationEmailPageComponent extends React.Component<RouteComponentProps> {
	@observable private isResending: boolean = false;
	@observable private resendResult: undefined | "success" | "failure";
	@observable private isEmailInvalid: boolean = false;
	@observable private submitted: boolean = false;

	private email: string = getQueryStringParameter("email") || "";

	public componentDidMount(): void {
		document.title = "Resend e-mail confirmation - crossMarket";
	}

	@action private readonly onEmailChange = (event: SyntheticEvent<HTMLInputElement>) => {
		this.email = event.currentTarget.value;
		this.isEmailInvalid = !EmailRegex.test(this.email);
	}

	private readonly resendConfirmation = async () => {
		runInAction(() => this.submitted = true);
		if (this.isEmailInvalid) {
			return;
		}

		runInAction(() => this.isResending = true);
		try {
			await service.resendConfirmationEmail(this.email, getQueryStringParameter("ReturnUrl"));
			runInAction(() => this.resendResult = "success");
		} catch {
			runInAction(() => this.resendResult = "failure");
		} finally {
			runInAction(() => this.isResending = false);
		}
	}

	private readonly returnToLogin = () =>
		goTo("/login", this.props)

	public render(): React.ReactNode {
		return (
			<Container>
				<h2>Resend confirmation code</h2>
				{this.resendResult === "success" &&
					<React.Fragment>
						<Alert color="success">
							An e-mail with the new confirmation code has been sent.
							Please check your inbox.
						</Alert>
						<Row>
							<Col md={{ size: 10, offset: 1 }} sm={12}>
								<Button
									type="button"
									color="primary"
									block
									onClick={this.returnToLogin}>
									Continue
								</Button>
							</Col>
						</Row>
					</React.Fragment>}
				{this.resendResult === "failure" &&
					<Alert color="danger">
						Failed to send a new confirmation code.
						Please make sure you have entered a correct e-mail.
					</Alert>}

				{this.resendResult !== "success" &&
					<InputGroupWithValidation
						isInvalid={this.submitted && this.isEmailInvalid}>
						<Input
							type="email"
							name="email"
							placeholder="E-mail"
							defaultValue={this.email}
							required
							onChange={this.onEmailChange}
							disabled={this.isResending} />
						<InputGroupAddon addonType="append">
							<Button
								type="button"
								color="secondary"
								onClick={this.resendConfirmation}
								disabled={this.isResending}>
								Send
							</Button>
						</InputGroupAddon>
						<FormFeedback valid={!this.isEmailInvalid}>
							Invalid email.
						</FormFeedback>
					</InputGroupWithValidation>}
			</Container>
		);
	}
}

export const ResendConfirmationEmailPage = withRouter(ResendConfirmationEmailPageComponent);