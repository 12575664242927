import React from "react";
import { Form } from "reactstrap";
import styled from "styled-components";
import { makeUrl } from "../../../infrastructure/httpClient";

import { ExternalProviderDto } from "../dto/ExternalProviderDto";
import { ExternalLogin } from "./ExternalLogin";

const List = styled.div`
	text-align: center;
	padding: 0;
	margin: -9px 0 15px;
	line-height: 30px;
`;

export const ExternalLoginList = (p: {
	externalProviders: ExternalProviderDto[],
	returnUrl: string }) =>
		<Form action={makeUrl("login/external")} method="POST">
			<input type="hidden" name="ReturnUrl" value={p.returnUrl} />
			<List>
				{p.externalProviders.map(externalProvider =>
					<ExternalLogin
						key={externalProvider.displayName}
						externalProvider={externalProvider} />)}
			</List>
		</Form>;