import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Button, Col } from "reactstrap";
import styled from "styled-components";
import { Container, RowWithMarginTop } from "../../components";
import { goTo } from "../../helpers/goTo";

const Header = styled.h2`
	text-align: center;
	margin-top: 20px;
	margin-bottom: 30px;
`;

const Text = styled.p`
	margin-bottom: 20px;
`;

const TextFooter = styled.p`
	margin-bottom: 0;
`;

class SignupSuccessPageComponent extends React.Component<RouteComponentProps> {
	public componentDidMount(): void {
		document.title = "Signup success - crossMarket";
	}

	private readonly returnToLogin = () => {
		goTo("/login", this.props);
	}

	public render(): React.ReactNode {
		return (
			<Container>
				<Header>
					Your account was successfully created
				</Header>
				<Text>
					Thank you for signing up for crossMarket!
					To complete the registration process, an e-mail
					with a confirmation link was sent to you.
				</Text>
				<Text>
					We hope you will receive many interesting projects.
				</Text>
				<TextFooter>
					If you do not receive the e-mail within a few minutes,
					please check your spam folder.
				</TextFooter>
				<RowWithMarginTop>
					<Col md={{ size: 10, offset: 1 }} sm={12}>
						<Button type="button" color="primary" block onClick={this.returnToLogin}>
							Continue
						</Button>
					</Col>
				</RowWithMarginTop>
			</Container>
		);
	}
}

export const SignupSuccessPage = withRouter(SignupSuccessPageComponent);