import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { action, computed, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React, { SyntheticEvent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
	Alert,
	Button,
	Col,
	FormFeedback,
	FormGroup,
	Input,
	InputGroupAddon,
	InputGroupText,
	Label } from "reactstrap";
import {
	Container,
	Heading,
	InputGroupWithValidation,
	LinkWithReturnUrl,
	RowWithMarginTop,
	Separator } from "../../components";
import { goTo } from "../../helpers/goTo";
import { getQueryStringParameter } from "../../helpers/queryString";
import { EmailRegex } from "../../helpers/regex";
import AccountService from "../../services/account/AccountService";
import { ExternalLoginList } from "./components/ExternalLoginList";
import * as C from "./components/StyledComponents";
import { ExternalProviderDto } from "./dto/ExternalProviderDto";
import { LoginStatus } from "./dto/LoginStatus";
import service from "./LoginService";

@observer
class LoginPageComponent extends React.Component<RouteComponentProps> {
	@observable private isLoading: boolean = true;
	@observable private isLoggingIn: boolean = false;
	@observable private externalProviders: ExternalProviderDto[] = [];
	@observable private loginStatus: LoginStatus | null = null;
	@observable private isUsernameInvalid: boolean = true;
	@observable private isPasswordInvalid: boolean = true;
	@observable private submitted: boolean = false;

	private username: string = "";
	private password: string = "";
	private rememberLogin: boolean = true;
	private returnUrl: string = "";
	private readonly externalError: string | null = getQueryStringParameter("error");

	@computed private get hasError(): boolean {
		const externalProvidersNotLoaded = !this.isLoading && this.externalProviders.length === 0;
		const hasNonSuccessfulLoginStatus =
			this.loginStatus !== null && this.loginStatus !== LoginStatus.Success;
		const hasExternalErrorAndFormNotYetSubmitted =
			!this.submitted && this.externalError !== null;

		return !this.isLoggingIn
			&& (externalProvidersNotLoaded
				|| hasNonSuccessfulLoginStatus
				|| hasExternalErrorAndFormNotYetSubmitted);
	}

	@computed private get errorMessage(): string {
		switch (this.loginStatus) {
			case LoginStatus.InvalidCredentials:
				return "Your e-mail address or your password is incorrect. "
					+ "Please check your login data and try again.";
			case LoginStatus.LockedOut:
				return "Maximum number of login attempts has been exceeded. "
					+ "Your account is locked for 5 minutes. Please try again later.";
		}

		if (!this.isLoading && this.externalProviders.length === 0) {
			return "Unable to load external providers. Identity Server may be unavailable.";
		}

		return this.externalError !== null ? this.externalError : "Unknown error.";
	}

	public async componentDidMount(): Promise<void> {
		document.title = "Login - crossMarket";
		this.returnUrl = getQueryStringParameter("ReturnUrl") || "";

		let externalProviders: ExternalProviderDto[];
		try {
			externalProviders = await service.getExternalProviders();
		} finally {
			runInAction(() => {
				this.externalProviders = externalProviders || [];
				this.isLoading = false;
			});
		}
	}

	@action private readonly onUsernameChange = (event: SyntheticEvent<HTMLInputElement>) => {
		this.username = event.currentTarget.value;
		this.isUsernameInvalid = !EmailRegex.test(this.username);
	}

	@action private readonly onPasswordChange = (event: SyntheticEvent<HTMLInputElement>) => {
		this.password = event.currentTarget.value;
		this.isPasswordInvalid = this.password.length === 0;
	}

	private readonly onRememberLoginChange = (event: SyntheticEvent<HTMLInputElement>) => {
		this.rememberLogin = event.currentTarget.checked;
	}

	private readonly login = async (e: React.MouseEvent<Button, MouseEvent>) => {
		// Prevent form submitting.
		e.preventDefault();
		runInAction(() => this.submitted = true);
		if (this.isUsernameInvalid || this.isPasswordInvalid) {
			return;
		}

		runInAction(() => this.isLoggingIn = true);
		try {
			const status = await service.login(
				this.username,
				this.password,
				this.rememberLogin,
				this.returnUrl);

			switch (status) {
				case LoginStatus.Success:
					AccountService.invalidateAccountData();
					if (this.returnUrl) {
						goTo("/redirect", this.props);
					} else {
						goTo("/account", this.props);
					}
					break;
				case LoginStatus.EmailNotConfirmed:
					goTo("/email-not-confirmed", this.props);
					break;
				default:
					runInAction(() => this.loginStatus = status);
					break;
			}
		} finally {
			runInAction(() => this.isLoggingIn = false);
		}
	}

	private get hasJustResetPassword(): boolean {
		return getQueryStringParameter("hasJustResetPassword", this.props.location.search)
			=== "true";
	}

	private get emailChanged(): boolean {
		return !this.hasError && getQueryStringParameter("emailChanged") === "1";
	}

	private get userDeleted(): boolean {
		return !this.hasError && getQueryStringParameter("userDeleted") === "1";
	}

	public render(): React.ReactNode {
		return (
			<Container>
				<Heading>
					<h2>Log in to crossMarket</h2>
					{this.hasError &&
						<Alert color="danger">{this.errorMessage}</Alert>}
					{this.hasJustResetPassword &&
						<Alert>Your password has been successfully reset.</Alert>}
					{this.emailChanged &&
						<Alert>
							Your e-mail address has been successfully changed.
							Please login to continue.
						</Alert>}
					{this.userDeleted &&
						<Alert>Your account has been successfully deleted.</Alert>}

					<div style={config.showExternalProviders ? {} : {display: "none"}}>
						{!this.isLoading && <ExternalLoginList
							externalProviders={this.externalProviders}
							returnUrl={this.returnUrl} /> }
						{this.isLoading && <C.ExternalProvidersLoading />}
					</div>
					<p>
						<span>Do not yet have an account? </span>
						<LinkWithReturnUrl to="/signup">Register</LinkWithReturnUrl>
						<span> for free.</span>
					</p>
				</Heading>

				<form name="loginForm">
					<InputGroupWithValidation
						isInvalid={this.submitted && this.isUsernameInvalid}>
						<InputGroupAddon addonType="prepend">
							<InputGroupText>
								<FontAwesomeIcon icon="envelope" />
							</InputGroupText>
						</InputGroupAddon>
						<Input
							type="email"
							name="username"
							placeholder="E-mail"
							required
							autoFocus
							onChange={this.onUsernameChange}
							disabled={this.isLoggingIn} />
						<FormFeedback valid={!this.isUsernameInvalid}>
							E-mail is invalid.
						</FormFeedback>
					</InputGroupWithValidation>
					<InputGroupWithValidation
						isInvalid={this.submitted && this.isPasswordInvalid}>
						<InputGroupAddon addonType="prepend">
							<InputGroupText>
								<FontAwesomeIcon icon="lock" />
							</InputGroupText>
						</InputGroupAddon>
						<Input
							type="password"
							name="password"
							placeholder="Password"
							required
							onChange={this.onPasswordChange}
							disabled={this.isLoggingIn} />
						<FormFeedback valid={!this.isPasswordInvalid}>
							Password is required.
						</FormFeedback>
					</InputGroupWithValidation>
					<C.RestorePassword>
						<LinkWithReturnUrl id="login_forgot-password" to="/send-password-reset-email">
							Forgot your password?
						</LinkWithReturnUrl>
					</C.RestorePassword>
					<Separator />
					<FormGroup check>
						<Label check>
							<Input
								type="checkbox"
								name="rememberLogin"
								defaultChecked
								onChange={this.onRememberLoginChange}
								disabled={this.isLoggingIn} />
							Stay logged in
						</Label>
					</FormGroup>
					<RowWithMarginTop>
						<Col md={{ size: 10, offset: 1 }} sm={12}>
							<Button
								type="submit"
								color="primary"
								block
								onClick={this.login}
								disabled={this.isLoggingIn}>
								{this.isLoggingIn ? "Logging in..." : "Log in"}
							</Button>
						</Col>
					</RowWithMarginTop>
				</form>
			</Container>
		);
	}
}

export const LoginPage = withRouter(LoginPageComponent);