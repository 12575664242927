import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled, { css } from "styled-components";
import { ExternalProviderDto } from "../dto/ExternalProviderDto";

const Item = styled.div`
	display: inline-block;
	margin: 0;
	list-style: none;
`;

const ButtonColor = (color: string) => css`
	background-color: ${p => p.theme.colors.textSecondary};
	transition: all 0.3s ease-in-out;

	&:hover {
		background-color: ${color};
		transition: all 0.3s ease-in-out;
	}
`;

const ButtonImage = (image: string) => css`
	position: relative;
	top: 9px;
	background-image: url(${image});
	background-repeat: no-repeat;
	background-size: cover;
	background-position-y: 0px;
`;

const ButtonImageHover = styled.div<{ image: string }>`
	width: 30px;
	height: 30px;
	background-image: url(${p => p.image});
	background-repeat: no-repeat;
	background-size: cover;
	background-position-y: -30px;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;

	&:hover {
		opacity: 1;
		transition: opacity 0.3s ease-in-out;
	}
`;

interface IButtonProps {
	color?: string;
	image?: string;
}

const Button = styled.button<IButtonProps>`
	display: block;
	width: 30px;
	height: 30px;
	margin: 0 4px;
	padding: 0;
	color: ${p => p.theme.colors.background};
	border: none;
	border-radius: 50%;
	font-family: unset;
	${p => p.color ? ButtonColor(p.color) : null}
	${p => p.image ? ButtonImage(p.image) : null}

	&:hover {
		color: ${p => p.theme.colors.background};
	}
`;

const Icon = styled(FontAwesomeIcon)`
	position: relative;
	top: -2px;
	vertical-align: middle;
`;

interface IProps {
	externalProvider: ExternalProviderDto;
}

export class ExternalLogin extends React.Component<IProps> {
	private makeLinkProps(): IButtonProps {
		switch (this.props.externalProvider.displayName) {
			case "Google":
				return { color: "#ff5733" };
			case "Facebook":
				return { color: "#3b5998" };
			case "ProZ.com":
				return { image: "./assets/social/proz.png" };
			default:
				throw new Error(
					`Unknown external provider: '${this.props.externalProvider.displayName}'.`);
		}
	}

	private getLinkIcon(): IconProp | undefined {
		switch (this.props.externalProvider.displayName) {
			case "Google":
				return ["fab", "google"];
			case "Facebook":
				return ["fab", "facebook-f"];
			case "ProZ.com":
				return undefined;
			default:
				throw new Error(
					`Unknown external provider: '${this.props.externalProvider.displayName}'.`);
		}
	}

	public render(): React.ReactNode {
		const linkProps = this.makeLinkProps();
		const linkIcon = this.getLinkIcon();

		return (
			<Item>
				<Button {...linkProps}
					type="submit"
					name="Provider"
					value={this.props.externalProvider.authenticationScheme}
					title={`Continue with ${this.props.externalProvider.displayName}`}>
					{linkIcon && <Icon icon={linkIcon} />}
					{linkProps.image && <ButtonImageHover image={linkProps.image} />}
				</Button>
			</Item>);
	}
}