import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { action, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React, { SyntheticEvent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Button, Col, FormFeedback, InputGroupAddon, InputGroupText, Row} from "reactstrap";
import {
	Container,
	InputGroupWithValidation,
	InputWithIcon,
	PasswordInputGroup,
	Separator } from "../../components";
import { goTo } from "../../helpers/goTo";
import { getQueryStringParameter } from "../../helpers/queryString";
import { EmailRegex } from "../../helpers/regex";
import { EmailCheckingIndicator, EmailCheckingState } from "./components/EmailCheckingIndicator";
import { Header } from "./components/Header";
import * as C from "./components/StyledComponents";
import { SignupStatus } from "./dto/SignupStatus";
import service from "./SignupService";

@observer
class SignupPageComponent extends React.Component<RouteComponentProps> {
	@observable private isSigningUp: boolean = false;
	@observable private password: string = "";
	@observable private submitted: boolean = false;
	@observable private emailCheckingState: EmailCheckingState = EmailCheckingState.Invalid;
	@observable private isPasswordValid: boolean = false;
	@observable private signupErrors: string[] = [];

	private email: string = "";
	private returnUrl: string = "";

	public componentDidMount(): void {
		document.title = "Signup - crossMarket";
		this.returnUrl = getQueryStringParameter("ReturnUrl") || "";
	}

	private readonly onEmailChange = async (event: SyntheticEvent<HTMLInputElement>) => {
		const email = this.email = event.currentTarget.value;
		if (!EmailRegex.test(this.email)) {
			runInAction(() => this.emailCheckingState = EmailCheckingState.Invalid);
			return;
		}

		runInAction(() => this.emailCheckingState = EmailCheckingState.Checking);
		const isEmailAvailable = await service.isEmailAvailable(email);

		if (email === this.email) {
			runInAction(() => this.emailCheckingState = isEmailAvailable
				? EmailCheckingState.Valid
				: EmailCheckingState.Invalid);
		}
	}

	@action private readonly onPasswordChange = (password: string, isPasswordValid: boolean) => {
		this.password = password;
		this.isPasswordValid = isPasswordValid;
	}

	private readonly signup = async (e: React.MouseEvent<Button, MouseEvent>) => {
		e.preventDefault();
		runInAction(() => this.submitted = true);
		if (this.emailCheckingState !== EmailCheckingState.Valid || !this.isPasswordValid) {
			return;
		}

		runInAction(() => this.isSigningUp = true);
		try {
			const result = await service.signup(
				this.email,
				this.password,
				this.returnUrl);

			switch (result.status) {
				case SignupStatus.Success:
					goTo("/signup-success", this.props);
					break;
				case SignupStatus.HasErrors:
					runInAction(() => this.signupErrors = result.errors);
					break;
				case SignupStatus.FailedToSendConfirmationEmail:
					goTo("/resend-confirmation-email", this.props, { email: this.email });
					break;
			}

		} finally {
			runInAction(() => this.isSigningUp = false);
		}
	}

	public render(): React.ReactNode {
		return (
			<Container>
				<Header signupErrors={this.signupErrors} />
				<C.RegistrationHint>
					Create your account and start networking.
				</C.RegistrationHint>

				<form name="signUpForm">
					<InputGroupWithValidation
						isInvalid={this.submitted
							&& this.emailCheckingState === EmailCheckingState.Invalid}>
						<InputGroupAddon addonType="prepend">
							<InputGroupText>
								<FontAwesomeIcon icon="envelope" />
							</InputGroupText>
						</InputGroupAddon>
						<InputWithIcon
							type="email"
							name="email"
							placeholder="E-mail"
							required
							autoFocus
							onChange={this.onEmailChange}
							disabled={this.isSigningUp}>
							<EmailCheckingIndicator state={this.emailCheckingState} />
						</InputWithIcon>
						<FormFeedback
							valid={this.emailCheckingState === EmailCheckingState.Valid}>
							This e-mail is either invalid or already taken.
						</FormFeedback>
					</InputGroupWithValidation>
					<PasswordInputGroup
							submitted={this.submitted}
							disabled={this.isSigningUp}
							onPasswordChange={this.onPasswordChange} />
					<Separator />
					<Row>
						<Col md={{ size: 10, offset: 1 }} sm={12}>
							<C.SignupButton
								type="submit"
								color="primary"
								block
								onClick={this.signup}
								disabled={this.isSigningUp}>
								{this.isSigningUp
									? "Creating an Account..."
									: "Register for Free"}
							</C.SignupButton>
						</Col>
					</Row>
				</form>
			</Container>
		);
	}
}

export const SignupPage = withRouter(SignupPageComponent);