import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { Container, LoadingIndicator } from "../../components";
import { goTo } from "../../helpers/goTo";
import service from "../../services/account/AccountService";

const DelayedShowAnimation = keyframes`
	to {
		visibility: visible;
	}
`;

const Header = styled.h2`
	text-align: center;
	margin-top: 20px;
	margin-bottom: 30px;
	visibility: hidden;
	animation: 0s linear 1s forwards ${DelayedShowAnimation};
`;

class LoadingPageComponent extends React.Component<RouteComponentProps> {
	public componentDidMount(): void {
		document.title = "Loading - crossMarket";
		service.loadAccountData().finally(() => this.doRedirect());
	}

	private doRedirect(): void {
		if (service.loggedIn) {
			goTo("/account", this.props);
		} else {
			goTo("/login", this.props);
		}
	}

	public render(): React.ReactNode {
		return (
			<Container>
				<Header>
					Loading account data...
				</Header>
				<LoadingIndicator />
			</Container>
		);
	}
}

export const LoadingPage = withRouter(LoadingPageComponent);