import { observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import { DelayedContainer, LoadingIndicator } from "../../components";
import { goTo } from "../../helpers/goTo";
import { getQueryStringParameter } from "../../helpers/queryString";
import service, { IFrameLogoutInfoDto } from "./LogoutService";

const Header = styled.h2`
	text-align: center;
	margin-top: 20px;
	margin-bottom: 30px;
`;

const IFrame = styled.iframe`
	display: none;
`;

@observer
class EndSessionPageComponent extends React.Component<RouteComponentProps> {
	@observable private isLoading = true;
	@observable private clientId = "";
	@observable private signoutUrl = "";
	@observable private returnUrl = "";

	public componentDidMount(): void {
		document.title = "Log out - crossMarket";

		const logoutId = getQueryStringParameter("logoutId");
		if (logoutId === null) {
			goTo("/", this.props);
			return;
		}
		service.getEndSessionInfo(logoutId).then(dto => this.initialize(dto));
	}

	private initialize(dto: IFrameLogoutInfoDto): void {
		if (dto === null) {
			goTo("/", this.props);
			return;
		}

		runInAction(() => {
			this.clientId = dto.clientId;
			this.signoutUrl = dto.signOutIFrameUrl;
			this.returnUrl = dto.postLogoutRedirectUri;
			this.isLoading = false;
		});
	}

	private readonly redirect = () => {
		goTo("/redirect", this.props, { ReturnUrl: this.returnUrl, clientId: this.clientId });
	}

	public render(): React.ReactNode {
		return (
			<React.Fragment>
				<DelayedContainer>
					{this.isLoading && <LoadingIndicator />}
					{!this.isLoading && <Header>
						You are now logged out.
					</Header>}
				</DelayedContainer>
				{!this.isLoading && <IFrame src={this.signoutUrl} onLoad={this.redirect} />}
			</React.Fragment>
		);
	}
}

export const EndSessionPage = withRouter(EndSessionPageComponent);