import React from "react";
import { Alert } from "reactstrap";
import styled from "styled-components";
import { Heading, LinkWithReturnUrl } from "../../../components";

const SignupError = styled.p`
	&&:last-child {
		margin-bottom: 0;
	}
`;

export const Header = (p: { signupErrors: string[] }) =>
	<Heading>
		<h2>Register now and start doing business</h2>
		{p.signupErrors.length > 0 &&
			<Alert color="danger">
				{p.signupErrors.map(e =>
					<SignupError key={e}>{e}</SignupError>)}
			</Alert>}

		<p>
			<span>Already registered? </span>
			<LinkWithReturnUrl to="/login">Log in</LinkWithReturnUrl>
			<span> here.</span>
		</p>
	</Heading>;