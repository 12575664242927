import { action, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React, { SyntheticEvent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Alert, Button, FormGroup, Input, Label } from "reactstrap";
import { Container, Heading, LoadingIndicator, PullRightContainer } from "../../components";
import { goTo } from "../../helpers/goTo";
import service from "../../services/account/AccountService";

@observer
class DeletePageComponent extends React.Component<RouteComponentProps> {
	@observable private isLoading: boolean = true;
	@observable private isAccepted: boolean = false;
	@observable private isSubmitting: boolean = false;
	@observable private error: string | null = null;

	public componentDidMount(): void {
		document.title = "Delete the account - crossMarket";
		service.loadAccountData().finally(() => this.initialize());
	}

	@action private initialize(): void {
		if (!service.loggedIn) {
			goTo("/login", this.props);
		}

		this.isLoading = false;
	}

	@action private readonly onAcceptedChange = (event: SyntheticEvent<HTMLInputElement>) => {
		this.isAccepted = event.currentTarget.checked;
	}

	private readonly delete = async () => {
		runInAction(() => {
			this.isSubmitting = true;
			this.error = null;
		});

		try {
			await service.delete();
			goTo("/login", this.props, { userDeleted: "1" });
		} catch (e) {
			runInAction(() => this.error = (e as Error).message);
		} finally {
			runInAction(() => this.isSubmitting = false);
		}
	}

	private readonly backToAccount = () => {
		goTo("/account", this.props);
	}

	public render(): React.ReactNode {
		return (
			<Container>
				<Heading>
					<h2>Delete the account</h2>
				</Heading>
				{this.error !== null &&
					<Alert color="danger">{this.error}</Alert>}
				{this.isLoading && <LoadingIndicator />}
				{!this.isLoading &&
					<React.Fragment>
						<Alert color="danger">
							Your account and all related data will be deleted
							<b> without possibility </b>
							to recover it.
						</Alert>
						<FormGroup check>
							<Label check>
								<Input
									type="checkbox"
									onChange={this.onAcceptedChange}
									disabled={this.isSubmitting} />
								<span>
									I have read and understood the information above.
								</span>
							</Label>
						</FormGroup>
						<PullRightContainer>
							<Button
								id="account_delete-button"
								color="danger"
								onClick={this.delete}
								disabled={!this.isAccepted || this.isSubmitting}>
								Delete
							</Button>
							<Button
								color="default"
								onClick={this.backToAccount}
								disabled={this.isSubmitting}>
								Cancel
							</Button>
						</PullRightContainer>
					</React.Fragment>}
			</Container>
		);
	}
}

export const DeletePage = withRouter(DeletePageComponent);