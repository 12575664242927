import { action, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Button, Form } from "reactstrap";
import { Container, LoadingIndicator } from "../../components";
import { goTo } from "../../helpers/goTo";
import { getQueryStringParameter } from "../../helpers/queryString";
import { makeUrl } from "../../infrastructure/httpClient";
import service from "../../services/account/AccountService";
import { AlertBlock, IAlertBlockProps } from "./components/AlertBlock";
import { ExternalLogins } from "./components/ExternalLogins";
import { LoginData } from "./components/LoginData";
import * as C from "./components/StyledComponents";

@observer
class AccountPageComponent extends React.Component<RouteComponentProps> {
	private readonly returnUrl: string | null = getQueryStringParameter("ReturnUrl");
	@observable private isLoading: boolean = true;
	@observable private isSubmitting: boolean = false;
	@observable private readonly alert: IAlertBlockProps = {
		message: null,
		error: false
	};

	public componentDidMount(): void {
		document.title = "Account details - crossMarket";
		service.loadAccountData().finally(() => this.initialize());
	}

	@action private initialize(): void {
		if (!service.loggedIn) {
			goTo("/login", this.props);
		}

		const queryError = getQueryStringParameter("error");
		if (queryError !== null) {
			this.setError(queryError);
		}

		const linkedExternalLogin = getQueryStringParameter("linkedExternalLogin");
		if (linkedExternalLogin !== null) {
			this.setSuccess(`External login with ${linkedExternalLogin} added successfully.`);
		}

		const emailChanged = getQueryStringParameter("emailChanged");
		if (emailChanged !== null) {
			this.setSuccess("E-mail has been changed successfully.");
		}

		this.isLoading = false;
	}

	@action private readonly setError = (message: string | null) => {
		this.alert.message = message;
		this.alert.error = true;
	}

	@action private readonly setSuccess = (message: string | null) => {
		this.alert.message = message;
		this.alert.error = false;
	}

	@action private readonly beginSubmit = () => {
		this.alert.message = null;
		this.alert.error = true;
		this.isSubmitting = true;
	}

	@action private readonly endSubmit = () => {
		this.isSubmitting = false;
	}

	private readonly return = async () => {
		goTo("/redirect", this.props, { toCrossMarket: "1" });
	}

	public render(): React.ReactNode {
		return (
			<Container>
				{!this.isLoading &&
					<Form action={makeUrl("account/logout")} method="POST">
						<C.LogoutButton type="submit">
							LOGOUT
						</C.LogoutButton>
					</Form>}
				<C.Heading>
					<h2>Account details</h2>
				</C.Heading>
				{this.isLoading && <LoadingIndicator />}
				{!this.isLoading &&
					<React.Fragment>
						<AlertBlock {...this.alert} />
						<LoginData
							isSubmitting={this.isSubmitting}
							beginSubmit={this.beginSubmit}
							endSubmit={this.endSubmit}
							setError={this.setError}
							setSuccess={this.setSuccess} />
						<div style={config.showExternalProviders ? {} : {display: "none"}}>
							<ExternalLogins
								logins={service.externalLogins!}
								returnUrl={this.returnUrl}
								isSubmitting={this.isSubmitting}
								beginSubmit={this.beginSubmit}
								endSubmit={this.endSubmit}
								setError={this.setError}
								setSuccess={this.setSuccess} />
						</div>
						<C.Headline text="Privacy" />
						<p>
							<span>You may click </span>
							<Link id="account_delete" to="/account/delete">here</Link>
							<span> to delete your account.</span>
						</p>
						<C.ReturnButtonBlock>
							<Button
								color="default"
								onClick={this.return}
								disabled={this.isSubmitting}>
								Return to crossMarket
							</Button>
						</C.ReturnButtonBlock>
					</React.Fragment>}
			</Container>
		);
	}
}

export const AccountPage = withRouter(AccountPageComponent);