import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { action, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React, { SyntheticEvent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
	Alert,
	Button,
	Col,
	Form,
	FormFeedback,
	Input,
	InputGroupAddon,
	InputGroupText,
	Row} from "reactstrap";
import {
	Container,
	Heading,
	InputGroupWithValidation,
	LinkButton,
	LinkButtonWithReturnUrl,
	PullRightContainer} from "../../components";
import { EmailRegex } from "../../helpers/regex";
import service from "./ResetPasswordService";

@observer
class SendPasswordResetEmailPageComponent extends React.Component<RouteComponentProps> {

	@observable private isEmailInvalid: boolean = true;
	@observable private submitted: boolean = false;
	@observable private requesting: boolean = false;
	@observable private requested: boolean = false;
	@observable private isError: boolean = false;

	private email: string = "";

	@action private readonly onEmailChange = (event: SyntheticEvent<HTMLInputElement>) => {
		this.email = event.currentTarget.value;
		this.isEmailInvalid = !EmailRegex.test(this.email);
	}

	private readonly resetPassword = async (e: React.MouseEvent<Button, MouseEvent>) => {
		e.preventDefault();
		runInAction(() => this.submitted = true);
		if (this.isEmailInvalid) {
			return;
		}

		runInAction(() => {
			this.requesting = true;
			this.isError = false;
		});
		try {
			await service.sendPasswordResetEmail(this.email);
			runInAction(() => this.requested = true);
		} catch (e) {
			runInAction(() => {
				this.requesting = false;
				this.isError = true;
			});
		}
	}

	public render(): React.ReactNode {
		return (
			<Container>
				<Heading smallVerticalMargin>
					<h2>Password reset</h2>
					{!this.requested &&
						<p>Please enter the e-mail you have used during the registration.</p>}
				</Heading>

				{this.isError && <Alert color="danger">
					We failed to send a reset password e-mail. Please try again later.
				</Alert>}

				{!this.requested &&
				<Form name="form">
					<InputGroupWithValidation
						isInvalid={this.submitted && this.isEmailInvalid}>
						<InputGroupAddon addonType="prepend">
							<InputGroupText>
								<FontAwesomeIcon icon="envelope" />
							</InputGroupText>
						</InputGroupAddon>
						<Input
							type="email"
							name="email"
							placeholder="E-mail"
							required
							autoFocus
							disabled={this.requesting}
							onChange={this.onEmailChange} />
						<FormFeedback id="reset-password_email-invalid" valid={!this.isEmailInvalid}>
							E-mail is invalid.
						</FormFeedback>
					</InputGroupWithValidation>
					<PullRightContainer>
						<Button
							type="button"
							id="reset-password_button"
							color="secondary"
							disabled={this.requesting}
							onClick={this.resetPassword}>
							{this.requesting ? "Sending..." : "Send"}
						</Button>
						<LinkButtonWithReturnUrl to="/login">Cancel</LinkButtonWithReturnUrl>
					</PullRightContainer>
				</Form>}

				{this.requested && <React.Fragment>
					<Alert>
						An e-mail with the instructions on how to reset the password has been sent.
						Please check your inbox.
					</Alert>
					<Row>
						<Col md={{ size: 10, offset: 1 }} sm={12}>
							<LinkButton href="http://www.crossmarket.net/">
								crossMarket Home
							</LinkButton>
						</Col>
					</Row>
				</React.Fragment>}

			</Container>);
	}
}

export const SendPasswordResetEmailPage = withRouter(SendPasswordResetEmailPageComponent);